import React, { FC, useCallback, useEffect } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CashAccountSelectModal } from '@service/bugo/components/CashAccountSelectModal';
import {
  MemberFormInputsEnum,
  MemberIFormInput,
  MemberIFormInputs,
} from '@service/bugo/interfaces/memberAddEdit.interface';
import { verifyCashAccount } from '@shared/api/cashAccount/cashAccount.controller';
import { feventHookUrl } from '@shared/api/fevent/fevent.hook';
import { Button } from '@shared/components/Button';
import DismissModal from '@shared/components/DismissModal';
import { Icon } from '@shared/components/icons';
import { LoadingModal } from '@shared/components/LoadingModal';
import ResizableBox from '@shared/components/ResizableBox';
import { RoundedTag } from '@shared/components/RoundedTag';
import TelField from '@shared/components/TelField';
import { TextField } from '@shared/components/TextField';
import { CheckBox } from '@shared/containers';
import AutoComplete from '@shared/containers/AutoComplete/AutoComplete';
import BankSelect from '@shared/containers/BankSelect/BankSelect';
import { relationTypeCategoryList } from '@shared/miscValues';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import {
  cashAccountListByFeventAtom,
  feventIdInMemberListEditorAtom,
  relationshipAtomFamily,
} from '../MemberListEditor/memberListEditor.atom';
import { CashAccountApprovedTag } from './CashAccountApprovedTag';
import { PhoneTag } from './PhoneTag';

const DURATION = 0.3;

interface IProps {
  memberItem: FieldArrayWithId<MemberIFormInputs, 'member', 'id'>;
  index: number;
  control: Control<MemberIFormInputs, any>;
  register: UseFormRegister<MemberIFormInputs>;
  setValue: UseFormSetValue<any>;
  update: UseFieldArrayUpdate<MemberIFormInputs, 'member'>;
  remove: UseFieldArrayRemove;
  foldAll: boolean;
}

const getIsConfirmButtonDisabled = (fieldArray: MemberIFormInput[], index: number) => {
  const field = fieldArray[index];
  if (!field) return true;
  const { accountHolder, cashAccount, bank, isVerified } = field;
  if (isVerified) return true;
  if (accountHolder && cashAccount && bank) return false;
  return true;
};

const MemberInfoCard: FC<IProps> = ({
  memberItem,
  index,
  control,
  setValue,
  register,
  remove,
  update,
  foldAll,
}: IProps) => {
  const { bugoRole } = useAuth();
  const foldId = `memberInfoCard-${memberItem._id}-${index}`;

  const [fold, setFold] = useRecoilState(simpleOpenAtomFamily(foldId));
  const cashAccountSelectOpenId = `memberInfoCard-cashAccountSelect-${index}`;
  const cashACcountDetachOpenId = `memberInfoCard-cashAccountDetach-${index}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(cashAccountSelectOpenId));
  const setCashAccountDetachOpen = useSetRecoilState(
    simpleOpenAtomFamily(cashACcountDetachOpenId),
  );
  const memberDeleteOpenId = `memberInfoCard-delete-${index}`;
  const setDeleteOpen = useSetRecoilState(simpleOpenAtomFamily(memberDeleteOpenId));

  useEffect(() => {
    if (memberItem.isNew) {
      setFold(false);
      setValue(`member.${index}.isNew`, false);
    } else {
      setFold(foldAll);
    }
  }, [foldAll, index, memberItem.isNew, setFold, setValue]);

  const feventId = useRecoilValue(feventIdInMemberListEditorAtom);

  const setDefaultRelationShip = useSetRecoilState(
    relationshipAtomFamily(feventId ?? 'newTemp'),
  );

  const fieldArray = useWatch({ control, name: 'member' });
  const { Check } = Icon();

  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const { isLoading: verifyingCashAccount, mutateAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(feventHookUrl(feventId ?? 'undefined', bugoRole()));
      },
    },
  );

  const cashAccountListByFevent = useRecoilValue(cashAccountListByFeventAtom);
  const verifiedCashAccountListByFevent = cashAccountListByFevent.filter((item) => {
    return item.isVerified;
  });

  const name = fieldArray[index]?.name ?? '';
  const phoneNumber = fieldArray[index]?.phoneNumber ?? '';
  const accountHolderSame = fieldArray[index]?.accountHolderSame ?? true;
  const isVerified = fieldArray[index]?.isVerified ?? true;
  const isConfirmButtonDisabled = getIsConfirmButtonDisabled(fieldArray, index);
  // 예금주 동일이 체크되었을 때 예금주명을 상주 이름으로 설정.
  useEffect(() => {
    if (accountHolderSame)
      setValue(`member.${index}.${MemberFormInputsEnum.AccountHolder}`, name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountHolderSame]);

  const onFoldBtnClick = () => {
    setFold((curr) => !curr);
    update(index, fieldArray[index] as MemberIFormInput);
  };

  const memberCashAccountDetach = () => {
    setValue(`member.${index}.${MemberFormInputsEnum.CashAccountId}`, undefined);
    setValue(`member.${index}.${MemberFormInputsEnum.CashAccount}`, '');
    setValue(`member.${index}.isVerified`, false);
    setValue(`member.${index}.cashAccountModalConfirmed`, false);
  };

  const onVerifyCashAccountHandler = useCallback(async () => {
    const verfiyFunc = async () => {
      const field = fieldArray[index];
      if (!field) return;

      const { accountHolder, cashAccount, bank, cashAccountId } = field;
      const cashAccountVerify = {
        _id: cashAccountId,
        bank: bank,
        account: cashAccount,
        name: accountHolder,
        fevent: feventId,
      };

      try {
        const { _id, isVerified, accountName, message } = await verifyCashAccount(
          cashAccountVerify,
          bugoRole(),
        );

        setValue(`member.${index}.${MemberFormInputsEnum.CashAccountId}`, _id);
        setValue(`member.${index}.${MemberFormInputsEnum.AccountHolder}`, accountName);
        setValue(`member.${index}.isVerified`, isVerified);
        setValue(`member.${index}.cashAccountModalConfirmed`, isVerified);
        if (isVerified) {
          // console.log('인증 완료');
          notificateSuccess(notifiacationInstance, '계좌인증완료');
        } else {
          // console.log('인증 실패', message);
          notificateError(notifiacationInstance, '계좌인증 실패', message, 'top', 4);
        }
      } catch (err) {
        console.error(err);
      }
    };
    await mutateAsync(verfiyFunc());
  }, [
    bugoRole,
    feventId,
    fieldArray,
    index,
    mutateAsync,
    notifiacationInstance,
    setValue,
  ]);

  return (
    <>
      <ResizableBox
        duration={DURATION}
        className="rounded-2xl font-bold shadow-smd theme-bg-1"
      >
        {/* Card Header */}
        <div className="flex items-center justify-between px-3 py-2">
          <div className="flex h-full flex-wrap items-center gap-1.5">
            <h4 className="leading-7">{memberItem.relationType}</h4>
            {fold && memberItem.name && (
              <RoundedTag text={memberItem.name} className="theme-bg-5"></RoundedTag>
            )}
            {fold && memberItem.phoneNumber && (
              <PhoneTag phoneNumber={memberItem.phoneNumber}></PhoneTag>
            )}
            {fold && memberItem.cashAccount && (
              <CashAccountApprovedTag
                isVerified={memberItem.isVerified}
                foldId={foldId}
              />
            )}
          </div>
          <div className="flex">
            <Button
              type="button"
              onClick={() => setDeleteOpen(true)}
              className="button-no-border h-full break-keep px-1 text-xs leading-5 text-warn"
            >
              삭제
            </Button>
            <Button
              type="button"
              onClick={() => onFoldBtnClick()}
              className={`button-no-border h-full break-keep px-1 text-xs leading-5 ${
                fold ? 'theme-text-main' : 'text-gray-300'
              }`}
            >
              {fold ? '펼치기' : '접기'}
            </Button>
          </div>
        </div>
        <div className={`grid grid-cols-2 gap-2 px-2 pb-3 ${fold ? 'hidden' : 'block'}`}>
          {/* 상주 성함 */}
          <TextField
            placeholder="상주 성함"
            className="w-full"
            {...register(`member.${index}.${MemberFormInputsEnum.Name}`)}
            onChange={(e) => {
              if (accountHolderSame) {
                setValue(
                  `member.${index}.${MemberFormInputsEnum.AccountHolder}`,
                  e.target.value,
                );
              }
              setValue(`member.${index}.${MemberFormInputsEnum.Name}`, e.target.value);
            }}
          />
          {/* 고인과의 관계 */}
          <AutoComplete
            key={`relationType-${memberItem.id}`}
            placeholder="고인과의 관계"
            optionList={relationTypeCategoryList}
            control={control}
            name={`member.${index}.${MemberFormInputsEnum.RelationType}`}
            onChangeFunc={(s: string) => {
              setDefaultRelationShip(s);
            }}
          />
          {/* 휴대전화번호 */}
          <div className="col-span-2">
            <TelField
              value={phoneNumber}
              register={register}
              name={`member.${index}.${MemberFormInputsEnum.PhoneNumber}`}
              className="w-full"
            />
          </div>
          {/* 예금주명 */}
          <TextField
            placeholder="예금주명"
            className="w-full"
            {...register(`member.${index}.${MemberFormInputsEnum.AccountHolder}`)}
          />
          {/* 예금주 동일 */}
          <label className="flex items-center border pl-4 theme-border-1">
            <CheckBox
              checked={accountHolderSame}
              onCheckedChange={(checked) =>
                setValue(
                  `member.${index}.${MemberFormInputsEnum.AccountHolderSame}`,
                  checked,
                )
              }
            />
            <span
              className={`flex-1 text-center ${accountHolderSame ? '' : 'theme-text-8'}`}
            >
              예금주 동일
            </span>
          </label>
          {/* 은행 선택 */}
          <div className="col-span-2 flex">
            <div className="flex-1">
              <BankSelect
                key={`bank-${memberItem.id}`}
                control={control}
                name={`member.${index}.${MemberFormInputsEnum.Bank}`}
                placeholder="은행 선택"
                disabled={isVerified}
              />
            </div>
            <div>
              {isVerified ? (
                <Button
                  type="button"
                  className={`button-no-border h-full w-[108px] bg-red-400 text-white`}
                  onClick={() => setCashAccountDetachOpen(true)}
                >
                  계좌삭제
                </Button>
              ) : (
                <Button
                  type="button"
                  disabled={verifiedCashAccountListByFevent.length === 0}
                  className={`button-no-border h-full w-[108px] text-white theme-bg-main disabled:theme-text-3 disabled:theme-bg-10`}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  계좌선택
                </Button>
              )}
            </div>
          </div>
          {/* 계좌번호 */}
          <div className="col-span-2 flex">
            <div className="flex-1">
              <TextField
                type="tel"
                placeholder="계좌번호"
                className="w-full"
                disabled={isVerified}
                {...register(`member.${index}.${MemberFormInputsEnum.CashAccount}`)}
              />
            </div>
            <Button
              disabled={isConfirmButtonDisabled}
              onClick={onVerifyCashAccountHandler}
              type="button"
              className={`button-no-border h-full w-[108px] ${
                isVerified || !isConfirmButtonDisabled
                  ? 'text-white theme-bg-main'
                  : 'theme-text-3 theme-bg-10'
              }`}
            >
              {isVerified ? (
                <div className="center-box font-bold">
                  <p>확인완료</p>
                  <Check />
                </div>
              ) : (
                '계좌확인'
              )}
            </Button>
          </div>
        </div>
      </ResizableBox>
      <CashAccountSelectModal
        openId={cashAccountSelectOpenId}
        index={index}
        setValue={setValue}
      />
      <DismissModal
        openId={cashACcountDetachOpenId}
        onClick={memberCashAccountDetach}
        buttonTitle="삭제"
        title={`${fieldArray[index]?.[MemberFormInputsEnum.Name]}님 계좌삭제`}
        subtitle="현재 연결된 계좌를 삭제합니다."
      ></DismissModal>
      <DismissModal
        openId={memberDeleteOpenId}
        onClick={() => {
          remove(index);
        }}
        buttonTitle={'삭제'}
        title={'상주님 삭제'}
        subtitle={`${
          fieldArray[index]?.[MemberFormInputsEnum.Name]
        } 상주님을 삭제하시겠습니까?`}
      />
      <LoadingModal open={verifyingCashAccount} title={'계좌 인증 중'}></LoadingModal>
    </>
  );
};

export default React.memo(MemberInfoCard);
