import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import {
  CashAccount,
  CashAccountCreate,
  CashAccountUpdate,
  CashAccountVerify,
  CashAccountVerifyResponse,
} from './cashAccount.interface';

const createCashAccount = async (
  cashAccountCreate: CashAccountCreate,
  bugoRole: LowerRole,
) => {
  const { data: cashAccount } = await api_.post<CashAccount>(
    `/${bugoRole}/cash-account`,
    cashAccountCreate,
  );
  return cashAccount;
};

const updateCashAccount = async (
  cashAccountUpdate: CashAccountUpdate,
  bugoRole: LowerRole,
) => {
  const { data: cashAccount } = await api_.patch<CashAccountUpdate>(
    `/${bugoRole}/cash-account/${cashAccountUpdate._id}`,
    cashAccountUpdate,
  );
  return cashAccount;
};

const verifyCashAccount = async (
  cashAccountVerify: CashAccountVerify,
  bugoRole: LowerRole,
) => {
  if (cashAccountVerify._id) {
    const { data } = await api_.patch(
      `/${bugoRole}/cash-account/${cashAccountVerify._id}`,
      cashAccountVerify,
    );

    const { isVerified, accountName, message } = (
      await api_.patch<CashAccountVerifyResponse>(
        `/${bugoRole}/cash-account/${cashAccountVerify._id}/verify`,
      )
    ).data;

    return {
      _id: cashAccountVerify._id,
      accountName,
      isVerified: isVerified,
      message,
    };
  } else {
    const { _id: cashAccountId } = await createCashAccount(cashAccountVerify, bugoRole);

    const { isVerified, accountName, message } = (
      await api_.patch<CashAccountVerifyResponse>(
        `/${bugoRole}/cash-account/${cashAccountId}/verify`,
      )
    ).data;

    return {
      _id: cashAccountId,
      isVerified: isVerified,
      message,
      accountName,
    };
  }
};

export { createCashAccount, updateCashAccount, verifyCashAccount };
